<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Relatório de Agendamentos</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-row>
              <v-col>
                <v-expansion-panels class="mb-5" :value="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Filtros
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container fluid>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="Paciente"
                              placeholder="Pesquise pelo nome/cpf/rg do paciente"
                              v-model="filters.patient"
                            >
                            </v-text-field
                          ></v-col>
                          <v-col>
                            <v-select
                              class="mr-2"
                              v-model="filters.status"
                              :items="schedulingStatuses"
                              label="Status"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!-- <v-col>
                            <v-select
                              class="mr-2"
                              v-model="filters.payment"
                              :items="paymentStatuses"
                              label="Pagamento"
                            >
                            </v-select>
                          </v-col> -->
                          <v-col>
                            <v-select
                              class="mr-2"
                              v-model="filters.CompanyBranchId"
                              :items="[
                                { value: null, text: 'Todas' },
                                ...companyBranches,
                              ]"
                              label="Matriz/filial"
                            >
                            </v-select>
                          </v-col>
                          <v-col>
                            <v-select
                              class="mr-2"
                              v-model="filters.agreement"
                              :items="[
                                { value: null, text: 'Todos' },
                                ...agreements,
                              ]"
                              :loading="loadingAgreements"
                              label="Convênio"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="Data inicial"
                              v-model="filters.startDate"
                              type="date"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Data final"
                              v-model="filters.endDate"
                              type="date"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <div class="d-flex justify-end align-center flex-wrap">
                          <v-btn
                            color="blue white--text"
                            class="mr-2"
                            @click="generateReports"
                          >
                            <v-icon left>mdi-content-save</v-icon> Gerar e
                            baixar</v-btn
                          >
                          <v-btn
                            color="success"
                            class="mr-2"
                            @click="loadSchedules(1)"
                          >
                            <v-icon left>mdi-creation</v-icon> Gerar</v-btn
                          >
                          <v-btn @click="clearFilters"
                            ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                          >
                        </div>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <v-row v-if="summary">
              <v-col class="text-center">
                <h2>Resumo</h2>
              </v-col>
            </v-row>

            <v-row v-if="summary">
              <v-col>
                <v-alert
                  border="left"
                  colored-border
                  color="blue"
                  elevation="2"
                >
                  Saldo: {{ formatSummaryValue(summary.balance) }}
                </v-alert>
              </v-col>
              <v-col>
                <v-alert
                  border="left"
                  colored-border
                  color="blue"
                  elevation="2"
                >
                  À receber: {{ formatSummaryValue(summary.toReceive) }}
                </v-alert>
              </v-col>
              <v-col>
                <v-alert
                  border="left"
                  colored-border
                  color="blue"
                  elevation="2"
                >
                  Previsto: {{ formatSummaryValue(summary.predictedTotal) }}
                </v-alert>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Ver detalhes" @click="openReportScreen(item.id)">
              <v-icon> mdi-file-search-outline </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.paymentStatus`]="{ item }">
            <v-icon color="blue" v-if="item.paymentStatus === 'paid'">
              mdi-check
            </v-icon>

            <v-icon v-else> mdi-clock-outline </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="currentPage"
        :length="numberOfPages"
        @input="loadSchedules()"
      ></v-pagination>
    </div>

    <v-dialog v-model="scheduleDetailsDialog" max-width="600">
      <SchedulingDetails
        :schedulingId="schedulingId"
        @close-dialog="scheduleDetailsDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent max-width="290">
      <v-card>
        <div class="pa-5 d-flex justify-center align-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import SchedulingDetails from "./SchedulingDetails.vue";
import { mapActions } from "vuex";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

import { saveAs } from "file-saver";

export default {
  components: {
    SchedulingDetails,
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data() {
    return {
      panel: 0,
      currentPage: 1,
      resultsPerPage: 50,
      totalRows: 0,
      search: null,
      schedulingId: null,
      scheduleDetailsDialog: false,
      loading: false,
      loadingDialog: false,
      loadingAgreements: false,
      globalExpectedReceipts: {},
      headers: [
        { text: "ID", value: "id" },
        { text: "Data", value: "startDate" },
        { text: "Paciente", value: "patientName" },
        { text: "Pagamento", value: "paymentMethod" },
        { text: "Total (R$)", value: "formattedTotal" },
        { text: "Subtotal (R$)", value: "formattedSubtotal" },
        { text: "Pago", value: "paymentStatus" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      paymentStatuses: [
        { value: "paid", text: "Pago" },
        {
          value: "pending",
          text: "Pagamento pendente",
        },
        {
          value: "canceled",
          text: "Cancelado",
        },

        { value: null, text: "Todos" },
      ],
      schedulingStatuses: [
        { value: "confirmed", text: "Confirmado" },
        {
          value: "pending",
          text: "Pendente",
        },
        {
          value: "waiting-for-service",
          text: "Aguardando atendimento",
        },
        {
          value: "in-progress",
          text: "Em atendimento",
        },
        {
          value: "patient-did-not-attend",
          text: "Paciente não atendido",
        },
        {
          value: "canceled",
          text: "Cancelado",
        },
        {
          value: "completed",
          text: "Paciente atendido",
        },
        { value: null, text: "Todos" },
      ],
      items: [],
      companyBranches: [],
      agreements: [],
      summary: null,
      filters: {
        patient: null,
        payment: null,
        agreement: null,
        status: null,
        CompanyBranchId: null,
        startDate: this.$moment()
          .subtract(30, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
        endDate: this.$moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    ...mapActions("schedulingReports", ["loadScheduleData"]),
    async loadSchedules(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: true,
          page: currentPage,
          ...this.filters,
        };

        const response = await this.$axios.get(`/reports/schedules`, {
          params,
        });

        // console.log(response.data);

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      }
    },
    async loadSchedulesForReports() {
      try {
        const params = {
          ...this.filters,
        };

        const response = await this.$axios.get(`/reports/schedules`, {
          params,
        });

        const data = [...response.data];

        this.calculateSummaryValues(data);

        return data;
      } catch (error) {
        console.log(error);
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      }
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        // console.log(item);

        item.patientName = item.Patient.Person.fullName;

        item.startDate = this.$moment(item.startDate).format("DD/MM/YYYY");

        item.paymentMethod = item.PaymentMethods.length
          ? this.getPaymentMethods(item.PaymentMethods)
          : "Não incluído";

        item.formattedTotal = parseFloat(item.total)
          .toFixed(2)
          .replace(".", ",");

        item.formattedSubtotal = parseFloat(item.subtotal)
          .toFixed(2)
          .replace(".", ",");

        if (item.PaymentMethods.length)
          item.paymentStatus = this.checkIfItsPaid({
            total: item.total,
            payments: item.PaymentMethods,
          });
        else item.paymentStatus = "pending";

        return item;
      });

      this.calculateSummaryValues(this.items);
    },
    getPaymentMethods(payments) {
      const copyOfData = [...payments];

      let paymentsAux = copyOfData.map((p) => {
        return p.SchedulingPaymentMethod.paymentType === "in-cash"
          ? p.name
          : `Parcelado (${p.SchedulingPaymentMethod.numberOfInstallments}x)`;
      });

      return paymentsAux.length > 1 ? paymentsAux.join(", ") : paymentsAux[0];
    },
    checkIfItsPaid(data) {
      const copyOfData = { ...data };
      const total = parseFloat(copyOfData.total);

      let totalPaid = 0.0;

      for (const p of copyOfData.payments) {
        if (p.SchedulingPaymentMethod.status === "paid")
          totalPaid += parseFloat(p.SchedulingPaymentMethod.value);
      }

      return totalPaid < total ? "pending" : "paid";
    },
    calculateTotalOfLocalExpectedReceipts(localExpectedReceipts) {
      // console.log(localExpectedReceipts);
      let copyOfData = { ...localExpectedReceipts };
      let total = 0.0;

      for (const key in copyOfData) {
        total += parseFloat(copyOfData[key]);
      }

      return total;
    },
    mergeBetweenGlobalAndLocalExpectedReceipts(localExpectedReceipts) {
      let local = { ...localExpectedReceipts };

      for (const key in local) {
        if (this.globalExpectedReceipts[key])
          this.globalExpectedReceipts[key] += parseFloat(local[key]);
        else this.globalExpectedReceipts[key] = parseFloat(local[key]);
      }
    },
    calculateSummaryValues(data) {
      const copyOfData = [...data];

      let balance = 0.0;
      let toReceive = 0.0;
      let predictedTotal = 0.0;

      for (const item of copyOfData) {
        if (item.Agreement && item.Agreement.receipt === "posteriorly")
          continue;

        const localExpectedReceipts = {};
        let totalPaid = 0.0;

        if (item.PaymentMethods.length) {
          for (const payment of item.PaymentMethods) {
            if (payment.SchedulingPaymentMethod.status === "paid") {
              balance += parseFloat(payment.SchedulingPaymentMethod.value);
              totalPaid += parseFloat(payment.SchedulingPaymentMethod.value);
            } else if (payment.SchedulingPaymentMethod.status === "pending") {
              let dueDate = this.$moment(
                payment.SchedulingPaymentMethod.dueDate
              ).format("DD/MM/YYYY");
              localExpectedReceipts[`${dueDate}`] = parseFloat(
                payment.SchedulingPaymentMethod.value
              );
            }
          }

          let totalThatIsExpectedToBeReceived = this.calculateTotalOfLocalExpectedReceipts(
            localExpectedReceipts
          );
          let noReceiptForecast =
            item.total - (totalPaid + totalThatIsExpectedToBeReceived);
          toReceive += noReceiptForecast;

          //faz um merge entre os objetos (local e global) dos valores com recebimento previsto
          this.mergeBetweenGlobalAndLocalExpectedReceipts(
            localExpectedReceipts
          );
        } else if (item.PaymentMethods && item.PaymentMethods.length === 0) {
          //isso aqui cobre tanto quando tem convenio e recebe depois quanto quando
          //não tem convênio e não foi incluído o pagamento
          toReceive += parseFloat(item.total);
        }
      }

      // console.log(this.globalExpectedReceipts);

      let range = moment.range(this.filters.startDate, this.filters.endDate);

      for (const key in this.globalExpectedReceipts) {
        // console.log(key);
        if (range.contains(key))
          toReceive += parseFloat(this.globalExpectedReceipts[key]);
      }

      predictedTotal += balance + toReceive;

      this.summary = {
        balance: parseFloat(balance).toFixed(2),
        toReceive: parseFloat(toReceive).toFixed(2),
        predictedTotal: parseFloat(predictedTotal).toFixed(2),
      };

      // console.log(this.summary);
    },
    async generateReports() {
      try {
        this.loading = true;

        let reportData = await this.loadSchedulesForReports();

        // console.log(reportData);

        const csvHeader = [
          `"","","", "", "Gerado em:","${this.$moment().format(
            "DD/MM/YYYY - HH:mm"
          )}"`,
        ];

        if (this.filters.startDate && this.filters.endDate)
          csvHeader.push(
            `"","","", "", "Período:","${this.$moment(
              this.filters.startDate
            ).format("DD/MM/YYYY")} à ${this.$moment(
              this.filters.endDate
            ).format("DD/MM/YYYY")}"`
          );

        csvHeader.push("");

        csvHeader.push(
          '"CÓDIGO","AGENDADO PARA","PACIENTE", "LOCAL", "STATUS","VALOR (R$)"'
        );

        const CSVArray = [...csvHeader];

        for (const scheduling of reportData) {
          let dateOfRealization = this.$moment(
            scheduling.dateOfRealization
          ).format("DD/MM/YYYY - HH:mm");

          let patientName = scheduling.Patient.Person.fullName;
          let branchName = scheduling.CompanyBranch.fantasyName;
          let status;
          let value = scheduling.total
            ? parseFloat(scheduling.total).toFixed(2)
            : parseFloat(scheduling.subtotal).toFixed(2);

          if (scheduling.status === "pending") status = "Pendente";
          else if (scheduling.status === "confirmed") status = "Confirmado";
          else if (scheduling.status === "waiting-for-service")
            status = "Aguardando atendimento";
          else if (scheduling.status === "in-progress")
            status = "Em atendimento";
          else if (scheduling.status === "patient-did-not-attend")
            status = "Paciente não atendido";
          else if (scheduling.status === "canceled") status = "Cancelado";
          else if (scheduling.status === "completed") status = "Atendido";

          //Gerando CSV
          CSVArray.push(
            `"${scheduling.id}","${dateOfRealization}","${patientName}","${branchName}","${status}","${value}"`
          );
        }

        CSVArray.push("");
        CSVArray.push("");
        CSVArray.push(
          `"","","","","SALDO (R$): ","${parseFloat(
            this.summary.balance
          ).toFixed(2)}"`
        );
        CSVArray.push(
          `"","","","","À RECEBER (R$): ","${parseFloat(
            this.summary.toReceive
          ).toFixed(2)}"`
        );
        CSVArray.push(
          `"","","","","PREVISTO (R$): ","${parseFloat(
            this.summary.predictedTotal
          ).toFixed(2)}"`
        );

        const CSV = "\ufeff" + CSVArray.join("\n");

        const csvFile = new Blob([CSV], { type: "text/csv;charset=UTF-8" });

        saveAs(
          csvFile,
          `Relatorio_de_Agendamentos-Gerado_em:${this.$moment().format(
            "DD-MM-YYYY"
          )}.csv`
        );
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async openReportScreen(schedulingId) {
      this.loadingDialog = true;

      await this.loadScheduleData(schedulingId);

      this.loadingDialog = false;
      this.scheduleDetailsDialog = true;
    },
    async loadCompanyBranches() {
      try {
        this.loadCompanyBranches = true;

        const url = `/company-branches`;

        const res = await this.$axios.get(url);

        this.setCompanyBranches(res.data);

        this.loadCompanyBranches = false;
      } catch (error) {
        this.loadCompanyBranches = false;

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      }
    },
    setCompanyBranches(data) {
      const copyOfData = [...data];

      this.companyBranches = copyOfData.map((cb) => ({
        text: cb.fantasyName,
        value: cb.id,
      }));
    },
    async loadAgreements() {
      try {
        this.loadAgreements = true;

        const url = `/agreements`;

        const res = await this.$axios.get(url);

        this.setAgreements(res.data);

        this.loadAgreements = false;
      } catch (error) {
        this.loadAgreements = false;

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      }
    },
    setAgreements(data) {
      const copyOfData = [...data];

      this.agreements = copyOfData.map((a) => ({
        text: a.name,
        value: a.id,
      }));
    },
    clearFilters() {
      this.filters = {
        patient: null,
        payment: null,
        agreement: null,
        status: null,
        CompanyBranchId: null,
        startDate: this.$moment()
          .subtract(30, "days")
          .startOf("day")
          .format("YYYY-MM-DD"),
        endDate: this.$moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
      };
    },
    formatSummaryValue(value) {
      return parseFloat(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  created() {
    this.loadCompanyBranches();
    this.loadAgreements();
  },
};
</script>

<style></style>
