<template>
  <v-card>
    <v-card-title>Detalhes do agendamento</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <strong>Criado em:</strong>
          {{ scheduling.createdAt }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong>Agendado para:</strong>
          {{ scheduling.dateOfRealization }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong>Das:</strong>
          {{ scheduling.startDate }}
        </v-col>
        <v-col>
          <strong>às:</strong>
          {{ scheduling.endDate }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong>Status:</strong>
          {{ scheduling.status }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong>Local:</strong>
          {{ scheduling.CompanyBranch.fantasyName }}
        </v-col>
      </v-row>

      <v-row class="mb-3" v-if="scheduling.Agreement">
        <v-col>
          <strong>Convênio:</strong>
          {{ scheduling.Agreement.name }}
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="mt-3">
        <v-col>
          <strong>Nome do paciente:</strong>
          {{ scheduling.Patient.Person.fullName }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong>CPF do paciente:</strong>
          {{ scheduling.Patient.Person.taxDocument }}
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong>Telefone de contato:</strong>
          {{ scheduling.Patient.Person.cellphone }}
        </v-col>
      </v-row>

      <v-row v-if="scheduling.Patient.Person.address">
        <v-col class="text-left">
          <strong>Endereço:</strong>
          {{
            scheduling.Patient.Person
              ? scheduling.Patient.Person.address +
                ", " +
                scheduling.Patient.Person.number
              : ""
          }}
        </v-col>
      </v-row>

      <v-row v-if="scheduling.Patient.Person">
        <v-col md="9" class="text-left">
          <strong>Bairro:</strong>
          {{
            scheduling.Patient.Person ? scheduling.Patient.Person.district : ""
          }}
        </v-col>
        <v-col md="3" class="text-right">
          <strong>Cep:</strong>
          {{
            scheduling.Patient.Person ? scheduling.Patient.Person.zipCode : ""
          }}
        </v-col>
      </v-row>

      <v-row v-if="scheduling.PaymentMethods.length">
        <v-col>
          <strong>Pagamento:</strong>
          {{ scheduling.paymentMethodsName }}
        </v-col>
      </v-row>

      <v-row v-if="scheduling.PaymentMethods.length">
        <v-col>
          <strong>Status do pagamento:</strong>
          {{ scheduling.paymentStatus }}
          <v-btn
            v-if="
              scheduling.paymentStatus === 'Pago' ||
              (scheduling.Agreement &&
                scheduling.Agreement.receipt === 'on-schedule')
            "
            class="ml-3"
            small
            outlined
            rounded
            text
            @click="printPaymentReceipt"
          >
            <v-icon left>mdi-printer-pos</v-icon> Imprimir recibo
          </v-btn>
        </v-col>
      </v-row>

      <h3 class="mt-5">PROCEDIMENTOS</h3>

      <v-divider></v-divider>

      <div
        class="mb-3 mt-3"
        v-bind:key="procedure.id"
        v-for="procedure in scheduling.Procedures"
      >
        <v-row class="mb-2">
          <v-col sm="8" class="text-left">{{ procedure.name }}</v-col>
          <v-col sm="4" class="text-right">
            R$
            {{
              procedure.SchedulingProcedure.price === null
                ? "-"
                : parseFloat(procedure.SchedulingProcedure.price)
                    .toFixed(2)
                    .replace(".", ",")
            }}
          </v-col>
        </v-row>
      </div>

      <v-divider></v-divider>

      <v-row>
        <v-col class="mt-2 text-right">
          <strong>Subtotal:</strong>
          R$
          {{ parseFloat(scheduling.subtotal).toFixed(2).replace(".", ",") }}
        </v-col>
      </v-row>

      <v-row v-if="scheduling.discount">
        <v-col class="mt-2 text-right">
          <strong>Desconto:</strong>
          R$ {{ discount }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <strong>Desconto pelo convênio:</strong>
          R$ {{ discountAgreement }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <strong>Total:</strong>
          R$
          {{ parseFloat(scheduling.total).toFixed(2).replace(".", ",") }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn text @click="closeDialog">
        <v-icon left>mdi-close</v-icon> Fechar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("schedulingReports", ["scheduling"]),
    discountAgreement() {
      let discountValue = 0.0;
      let discountAgreement = this.scheduling.Agreement
        ? parseFloat(this.scheduling.Agreement.discount)
        : 0.0;

      discountValue = (this.scheduling.subtotal * discountAgreement) / 100;

      return discountValue.toFixed(2).replace(".", ",");
    },
    discount() {
      let discountValue = 0.0;

      if (this.scheduling.discountInPercentageFormat) {
        discountValue =
          (this.scheduling.subtotal * parseFloat(this.scheduling.discount)) /
          100;
      } else {
        discountValue = parseFloat(this.scheduling.discount);
      }

      return discountValue.toFixed(2).replace(".", ",");
    },
  },
  data() {
    return {};
  },
  methods: {
    printPaymentReceipt() {
      let scheduling = { ...this.scheduling };

      // console.log(scheduling);

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=400,height=900,toolbar=0,scrollbars=0,status=0"
      );

      let documentBody = "";

      let patientName =
        scheduling.Patient.Person.type === "physical-person"
          ? scheduling.Patient.Person.fullName
          : `${scheduling.Patient.Person.fantasyName}`;

      documentBody += `<!DOCTYPE html>
                          <html>
                            <head>
                            <title>Recibo - Agendamento: #${scheduling.id} (${patientName})</title>
                            <style type="text/css">
                              body { font-size: 12px; font-family: Arial, Helvetica, sans-serif; }
                            </style>
                            </head>
                            <body>`;

      documentBody += `<p><b>${scheduling.CompanyBranch.fantasyName}</b></p>`;
      // documentBody += `<p>${scheduling.CompanyBranch.address}, ${scheduling.CompanyBranch.number} </p>`;

      documentBody += `<h2 style="text-align: center">RECIBO</h2>`;

      documentBody += `<p>PACIENTE: ${patientName}</p>`;
      documentBody += `<p>RESPONSÁVEL PELO PAGAMENTO: ${scheduling.PaymentMethods[0].SchedulingPaymentMethod.nameOfResponsible}</p> <hr>`;

      documentBody += `<p> RECEBEMOS R$ ${parseFloat(scheduling.total)
        .toFixed(2)
        .replace(".", ",")} EM: ${this.$moment(
        scheduling.PaymentMethods[0].SchedulingPaymentMethod.payDay
      ).format("DD/MM/YYYY")} REFERENTE À: </p>`;

      for (const s of scheduling.Procedures) {
        documentBody += `+ ${s.name} (R$ ${
          s.SchedulingProcedure.price === null
            ? ""
            : s.SchedulingProcedure.price.replace(".", ",")
        })<br>`;
      }

      if (scheduling.PaymentMethods.length) {
        documentBody += `<br> SENDO QUE o pagamento se deu da seguinte forma: <br>`;
        documentBody += `${scheduling.paymentMethodsName}. <br><br>`;
      }

      documentBody += `- Desconto = (R$ ${this.discount})<br>`;

      documentBody += `- Desconto pelo convênio = (R$ ${this.discountAgreement})<br>`;

      documentBody += "<br>";

      documentBody += `<p style="text-align: center">________________________________</p>`;
      documentBody += `<p style="text-align: center">Representante MultimagemRA</p>`;

      documentBody += `<p style="text-align: center"> IMPRESSO EM: ${this.$moment().format(
        "DD/MM/YYYY [ás] HH:mm"
      )} </p>`;

      documentBody += `</body></html>`;

      WinPrint.document.write(documentBody);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style></style>
